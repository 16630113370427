@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  background: #13181b;
  color: #68e9f2;

  margin: 0;
  font-family: "Merienda";
}

.studio-name {
    position: absolute;
    top: 1px;
    left: 16px;
    font-size: 18px;
    z-index: 99;
    background-color: #00000033;
}

.logout-button {
    position: absolute;
    top: 8px;
    right: 16px;
    font-size: 14px;
    z-index: 99;
    background-color: #00000055;
    border: none;
  }
  
  [data-amplify-authenticator] {
    --amplify-components-textfield-color: #ffffff;
  }
  
  